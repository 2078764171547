//
// This file will be copied as environment.ts. Put its import statement as high as
// possible int the index.tsx file to help prevent race conditions.
import { BaseURLMap, BaseURL } from "ares-core/IO/Http";

export const ARES_DEVELOPMENT = true;

export const REACT_APP_STRIPE_KEY='pk_test_51MHUTLJPqlMypbZJ76pPvMNxMqoEUUuvTPKtuq37bF0vXDBRrVlk9Knaf9LpohKuiUP24Uc9VsbZg93zwGTamAYm00uhR2edfl';
export const REACT_APP_STRIPE_VERSION='2022-11-15';

// To be read from SWA Function App and then overridden by Environment
BaseURLMap.set(BaseURL.LINK, "areservationapi-dev.azurewebsites.net");

