import React, { useEffect, useMemo, useState } from 'react'
import { IApplicationStore } from 'AppState';
import { DateTimeUtils, LinkCompany, Locale, QuestionAnswer, Shopping, Validation } from 'ares-core';
import { LinkEventDetail, ReservationData, Rate, SelectedSeats, ReservationDetail, TicketPerson, IContactInfo, CatalogDetail, ICatalogDisplay, IGiftCardInformation, ICartDTO, NotificationsType, SeatAssignmentMode } from 'ares-core/Models';
import { useSelector, shallowEqual } from 'react-redux'
import { Card, CardContent, Snackbar, Typography } from '@material-ui/core';
import { isLinkEventAvailable } from 'ares-core/Business/LinkEvent/Events';
import { DateRatePanelComponent } from './DateRatePanel';
import { useHistory, useParams } from 'react-router-dom';
import { startOfMonth, add, endOfMonth, format } from 'date-fns';
import SeatPicker, { buildAvailableCategories } from 'modules/Seats/SeatPicker';
import PanelComponent from 'link-core/UI/Components/PanelComponent';
import ContactInfoPanel from './ContactInfoPanel';
import DeliveryAddressPanelComponent from './DeliveryAddressPanel';
import QuestionsPanel from './QuestionsPanel';
import { MerchandisePanelComponent } from './MerchandisePanel';
import { GifCardDialog } from 'ares-core/UI/GiftCard/GiftCardInformation';
import NotificationsApi from 'ares-core/Notifications/NotificationsApi';
import { CoreStatus } from 'ares-core/Types';
import service from "ares-core/Services/Link/LinkCartService";
import { Alert } from '@material-ui/lab';
import CatalogService from "../../../ares-core/Services/CatalogService";

type eventUrlParams = {
  [key: string]: string;
};
export interface IPreGiftCardInformation {
  catalogId: number
  giftCardInformation: IGiftCardInformation[]
}
const iterateTickets = (
  ticketEdits: Partial<ReservationDetail>[],
  callback: (rateId: number, ticketId: number, ticket: TicketPerson) => void,
) => {
  ticketEdits.forEach((details) => {
    const tickets = details.ticketPersons || [];
    tickets.forEach((ticket) => {
      const rateId = details.rateId;
      const ticketId = ticket.ticketPersonId;
      if (rateId !== undefined && ticketId !== undefined) {
        // We execute callback per ticket
        callback(rateId, ticketId, ticket);
      }
    });
  });
};
const hasQuestions = (
  questionsJson: string,
  ticketRequiredFields: number,
  ticketEdits: Partial<ReservationDetail>[] = [],
): boolean => {
  const totalTickets = ticketEdits?.reduce(
    (acc, el) => acc + (el.tickets || 0),
    0,
  );
  // Check at least one ticketEdit has a questionGroup
  const hasTicketGroup: boolean =
    ticketEdits.filter((details) => details?.questionGroupId).length > 0;

  // this does not work -> if (!isEmpty(questionsJson?.length)) return true;
  if (questionsJson != null) {
    // Questions are stringified JSON Objects in an array so "[{}]" is a minimum
    if (questionsJson.length > 3) {
      return true;
    }
  }
  // At least one ticketPerson with required fields or rate questions group
  return totalTickets > 0 && (ticketRequiredFields > 0 || hasTicketGroup);
};

const ReservationWizard = () => {
  const { currentEvent, currentReservation, cartErrors, lastReservationDate, eventDates, eventTimes, seatsWorkspace, companySlug, companyId } = useSelector((state: IApplicationStore) => ({
    cartErrors: state.shopping.cart.errors,
    currentEvent: state.company.currentEvent,
    currentReservation: state.company.currentReservation,
    lastReservationDate: state.company.lastReservationDate,
    eventDates: state.company.eventDates,
    eventTimes: state.company.eventTimes,
    seatsWorkspace: state.company.seatsWorkspace,
    companySlug: state.shopping.companyId, // Idk who put this awful name
    companyId: state.company.companyID
  }), shallowEqual);

  const history = useHistory();
  const { urlEventDate }: eventUrlParams = useParams();
  const [panelState, setPanelState] = useState<{ [key: string]: boolean; }>({});
  const [allowedPanels, setAllowedPanels] = useState<string[]>(["dateRate"]);
  const [displayedPanels, setDisplayedPanels] = useState<string[]>([]);
  const [lastPanel, setLastPanel] = useState<string>("");
  const [giftCardSelected, setGiftCardSelected] = useState<{ catalogId: number, count: number }>({ catalogId: 0, count: 0 })
  const [giftCardInformation, setGiftCardInformation] = useState<IPreGiftCardInformation[]>([])
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentError, setCurrentError] = useState<number>(0);
  const [catalogItems, setCatalogItems] = useState<CatalogDetail[]>([]);
  const [clearSeats, setClearSeats] = useState<boolean>(false);
  const { setOpen, BuildGiftCardComponent } = GifCardDialog()

  const [defaultContact, _] = useState<Partial<IContactInfo>>(
    JSON.parse(localStorage["contact"] || "{}"),
  );

  const availableCategories = useMemo(() => {
    const { availableCategories } = buildAvailableCategories(
      (currentEvent.rates || []).filter(x => x.seatAssingmentCategoryId !== null),
      currentReservation.ticketEdits!
    )
    return availableCategories.length > 0
  }, [currentEvent.rates, currentReservation.ticketEdits])

  // dateRate panel opens on dialog acknowledge. Only on initial load.
  useEffect(() => {
    setPanelState((prevExpanded) => {
      return { ...prevExpanded, dateRate: true };
    });
  }, []);

  useEffect(() => {
    // Clean up residual from last reservation
    LinkCompany.ClearReservation();
  }, [currentEvent]);

  // determine which panels to display
  useEffect(() => {
    const panelsToDisplay = ["dateRate"];
    const { delivery, questionsJson, ticketRequiredFields, addOns } = currentEvent;
    const { seatAssignmentChartKey, seatAssignmentMode } = currentEvent
    const ticketEdits = currentReservation.ticketEdits || [];

    if (seatAssignmentChartKey && availableCategories && seatAssignmentMode === SeatAssignmentMode.Manual) {
      panelsToDisplay.push('SeatsPicker')
    }

    panelsToDisplay.push("contact")

    if (delivery) {
      panelsToDisplay.push("delivery");
    }
    if (hasQuestions(questionsJson, ticketRequiredFields, ticketEdits)) {
      panelsToDisplay.push("questions");
    }
    if (addOns.length) {
      panelsToDisplay.push("merchandise");
    }

    setLastPanel(panelsToDisplay[panelsToDisplay.length - 1]);
    setDisplayedPanels(panelsToDisplay);
  }, [currentEvent, currentReservation.ticketEdits]);

  useEffect(() => {
    if (cartErrors.length > 0) {
      setDisplayAlert(true);
      setErrors(cartErrors);
    } else {
      setDisplayAlert(false);
    }
  }, [cartErrors]);

  if (!isLinkEventAvailable(currentEvent)) {
    return (
      <Card style={{ height: "25rem", textAlign: "center" }}>
        <CardContent>
          <Typography variant="h4">{Locale.noEvent}</Typography>
        </CardContent>
      </Card>
    )
  }

  const shouldDisplayPanel = (panel: string) => {
    return displayedPanels.includes(panel)
  }

  const showNextError = () => {
    if (displayAlert) {
      let nextError = currentError + 1;
      if (nextError === errors.length) {
        // We show all errors at this point
        nextError = 0;
        setDisplayAlert(false);
        Shopping.Actions.SetCartErrors([]);
      }
      setCurrentError(nextError);
    }
  }

  const handlePanelChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (allowedPanels.includes(panel)) {
      setPanelState((prevExpanded) => ({ [panel]: isExpanded }));
    }
  }

  const expandablePanel = (panelName: string) => {
    return allowedPanels.includes(panelName);
  }

  const handleContinue = (resData: any, currPanel: string) => {
    ProcessPanelUpdates(resData, currPanel);
    const currPanelIdx = displayedPanels.indexOf(currPanel);
    const nextPanelIdx = currPanelIdx + 1;
    const nextPanel = displayedPanels[nextPanelIdx] ?? null;
    let new_allowedPanels = allowedPanels;
    if (!allowedPanels.includes(nextPanel)) {
      new_allowedPanels = allowedPanels.concat([nextPanel]);
    }
    // If this is the Last Panel Save/Add the Reservation
    if (nextPanel) {
      if (nextPanelIdx < displayedPanels.length) {
        // If you want to disable the auto collapse
        // you should add the ...prevExpaned, to
        // the new state setting.
        // It wasn't included, because we still have
        // the continue buttons appearing.
        setPanelState((prevExpanded) => ({
          [nextPanel]: true,
        }));
      }
      setAllowedPanels(new_allowedPanels);
    } // Notify that all panels have been visited
    else {
      setPanelState((prevExpanded) => ({}));
      // Scroll to top when done
      document.body.scroll({ top: 0, left: 0, behavior: "smooth" });
      // TODO - If we allow edits to a completed Reservation the we need to
      // save/add the reservation in another place.
      // The server will apply Deposit Calculations
      var reservationUpdated: ReservationData = LinkCompany.Store().currentReservation;
      var currentEventUpdated: LinkEventDetail = LinkCompany.Store().currentEvent;
      reservationUpdated.thisIsDeposit = currentEventUpdated.depositOnly;
      var asyncCaller = async () => {
        await Shopping.Api.addReservation(reservationUpdated);
        if (cartErrors.length == 0) {
          const slug = companySlug
          history.push(`/cart/${slug}`);
        }
      };
      asyncCaller();
    }
  }

  const handleGetEventDates = (startDate: Date, endDate?: Date) => {
    // date-fns functions
    var start = startOfMonth(startDate);
    var end = startOfMonth(add(startDate, { months: 1 }));
    if (endDate) {
      end = endOfMonth(endDate);
    }
    LinkCompany.Api.loadEventDates(
      currentEvent.eventId,
      DateTimeUtils.toYYYYMMDD(start),
      DateTimeUtils.toYYYYMMDD(end),
    );
  }

  const handleSetDate = (newDate: Date) => {
    LinkCompany.Actions.SetLastReservationDate(format(newDate, "yyyy-MM-dd"));
  }

  const handleValidDateRate = (valid: boolean) => {
    setPanelState({ dateRate: true });
    let a = ["dateRate"];
    setAllowedPanels(a);
  }

  const handleMerchandiseUpdate = (item: ICatalogDisplay | CatalogDetail, count: number) => {
    if (!item.giftCard) {
      LinkCompany.UpdateReservationCatalogItem(item, count)
      return
    }
    let giftCards: IGiftCardInformation[] = []
    let exists = giftCardInformation.find(x => x.catalogId === item.catalogId)?.giftCardInformation
    if (exists && exists.length > 0) {
      giftCards = exists.concat({
        giftCardTemplateId: 1,
        recipientName: "",
        recipientEmail: "",
        note: ""
      })
      const saveGC = giftCardInformation.map(item => {
        if (item.catalogId === item.catalogId) {
          item.giftCardInformation = giftCards
        }
        return item
      })
      count += saveGC.length
      setGiftCardInformation(saveGC)
    } else {
      giftCards.push({
        giftCardTemplateId: 1,
        recipientName: "",
        recipientEmail: "",
        note: ""
      })
      const saveGC = giftCardInformation.concat({ catalogId: item.catalogId!, giftCardInformation: giftCards })
      setGiftCardInformation(saveGC)
    }
    setOpen(true)
    setGiftCardSelected({ catalogId: item.catalogId, count: count })
  }

  const getSelectedSeats = (seats: SelectedSeats[]) => {
    handleContinue(seats, "SeatsPicker")
  }

  const handleChangeGiftCardInformation = (name: string, value: string, index: number, catalogId: number) => {
    let exists = giftCardInformation.find(x => x.catalogId === catalogId)
    let new_data: IGiftCardInformation[] = []
    let initialInfo = {
      giftCardTemplateId: 1,
      recipientName: "",
      recipientEmail: "",
      note: ""
    }
    if (exists) {
      if (exists.giftCardInformation.at(index)) {
        new_data = exists.giftCardInformation.map((item: any, indx: number) => {
          if (indx === index) {
            item[name] = value
          }
          return item
        })
        let updated_info = giftCardInformation.map(item => {
          item.giftCardInformation = new_data
          return item
        })
        setGiftCardInformation(updated_info)
      } else {
        //condition needed to know when there is a catalogId but not the current information is in it when more than 1 giftcard of the same CatalogId exists
        //@ts-ignore
        initialInfo[name] = value
        new_data = [initialInfo]
        setGiftCardInformation(giftCardInformation.map(item => {
          if (item.catalogId === catalogId) {
            item.giftCardInformation = item.giftCardInformation.concat(new_data)
          }
          return item
        }))
      }
    } else {
      //@ts-ignore
      initialInfo[name] = value
      setGiftCardInformation(giftCardInformation.concat({ catalogId: catalogId, giftCardInformation: [initialInfo] }))
    }
  }

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    showNextError();
  }

  const SaveGiftCardInformation = async () => {
    let information = giftCardInformation.find(x => x.catalogId === giftCardSelected.catalogId)
    if (information?.giftCardInformation.length !== giftCardSelected.count) {
      NotificationsApi.add({
        type: NotificationsType.error,
        message: 'You need to fill all the information before continue'
      })
      return
    }
    const emailIsValid = information.giftCardInformation.find(x => Validation.Email(x.recipientEmail) !== '' || Validation.Name(x.recipientName) !== '')
    if (emailIsValid) {
      NotificationsApi.add({
        type: NotificationsType.error,
        message: 'You need to fill all the information before continue'
      })
      return
    }
    //we get the last element of the array because we only need to send one GC per call
    const last_gc_info = information?.giftCardInformation[information?.giftCardInformation.length - 1]
    const { payload, status } = await service.addGiftCartdInformation(Shopping.Store().companyId, [last_gc_info] , Shopping.Store().cart.cartId, information?.catalogId!)
    if (status === CoreStatus.SUCCESS) {
      const cart = payload as ICartDTO
      Shopping.Api.addGiftCard(cart)
    }
    NotificationsApi.add({
      type: NotificationsType.success,
      message: 'Gift card information saved'
    })
    setOpen(false)
    setGiftCardSelected({ catalogId: 0, count: 0 })
  }

  const loadCatalogItems = (eventDateId: number) => {
    CatalogService.getCatalogByEventDate(companyId, eventDateId).then((items) => {
      if (items != null) {
        setCatalogItems(items);
      } else {
        setCatalogItems([]);
      }
    })
  }

  const ProcessPanelUpdates = (updates: any, panelName: string) => {
    if (panelName === "dateRate") {
      // *** WARNING *** Make sure to check mapping of Rate (eventDateID) and Reservation (eventD
      LinkCompany.UpdateReservation({
        eventDateId: parseInt(updates.eventDateID),
      });

      loadCatalogItems(Number(updates.eventDateID));

      for (let ticket of updates.ticketEdits) {
        LinkCompany.UpdateReservationRate(ticket.rateId, ticket.tickets);
      }
      if (currentReservation.eventDateId && availableCategories) {
        setClearSeats(true)
      }
    } else if (panelName === "questions") {
      const data = updates as {
        reservationDetails: Partial<ReservationDetail>[];
        eventAnswers: QuestionAnswer[];
      };
      const eventAnswers = data.eventAnswers;
      if (eventAnswers?.length > 0) {
        LinkCompany.UpdateReservation({
          answersJson: JSON.stringify(eventAnswers),
        });
      }
      // We call UpdateReservationRateInfo per ticket
      const ticketEdits = data.reservationDetails;
      iterateTickets(ticketEdits, LinkCompany.UpdateReservationRateInfo);
    } else if (panelName === "SeatsPicker") {
      LinkCompany.AddSeatsToTickets(updates)
      setClearSeats(false)
    } else {
      if (panelName === "contact") {
        localStorage["contact"] = JSON.stringify(updates);
      }
      LinkCompany.UpdateReservation(updates);
    }
  }

  return (
    <React.Fragment>
      <DateRatePanelComponent
        defaultDate={urlEventDate || undefined}
        expanded={expandablePanel("dateRate") && (panelState["dateRate"] ?? false)}
        handleContinue={handleContinue}
        handlePanelChange={handlePanelChange}
        lastReservationDate={lastReservationDate}
        currentEvent={currentEvent}
        currentReservation={currentReservation}
        eventDates={DateTimeUtils.filterPast(eventDates) as string[]}
        eventId={currentEvent.eventId}
        eventTimes={eventTimes}
        rates={currentEvent.rates ? currentEvent.rates.filter(
          ({ internal }: Rate) => internal === false,
        ) : []}
        validTrigger={handleValidDateRate}
        handleGetEventDates={handleGetEventDates}
        handleSetDate={handleSetDate}
      />
      {
        currentReservation.eventDateId && availableCategories && currentEvent.seatAssignmentMode === SeatAssignmentMode.Manual ?
          <PanelComponent
            panelTitle='Select your seats'
            panelName='SeatsPicker'
            expanded={expandablePanel("SeatsPicker") && (panelState["SeatsPicker"] ?? false)}
            handlePanelChange={handlePanelChange}>
            <SeatPicker
              workspaceKey={seatsWorkspace!}
              chartKey={currentEvent.seatAssignmentChartKey ?? ""}
              event={currentReservation.eventDateId.toString()}
              rates={currentEvent.rates.filter(x => x.seatAssingmentCategoryId !== null)}
              tickets={currentReservation.ticketEdits!}
              actionButton={true}
              getSeats={getSelectedSeats}
              clearSeatsSelection={clearSeats}
            />
          </PanelComponent>
          : <></>
      }
      <ContactInfoPanel
        defaultContact={{
          firstName: currentReservation.firstName || defaultContact.firstName,
          lastName: currentReservation.lastName || defaultContact.lastName,
          phone: currentReservation.phone || defaultContact.phone,
          email: currentReservation.email || defaultContact.email,
        }}
        nextStepLabel={lastPanel === "contact" ? Locale.completeReservation : Locale.continue}
        expanded={expandablePanel("contact") && (panelState["contact"] || false) && currentReservation.eventDateId > 0}
        handlePanelChange={handlePanelChange}
        handleContinue={handleContinue}
      />
      {
        shouldDisplayPanel("delivery") &&
        <DeliveryAddressPanelComponent
          expanded={expandablePanel("delivery") && (panelState["delivery"] ?? false)}
          nextStepLabel={lastPanel === "delivery" ? Locale.completeReservation : Locale.continue}
          handlePanelChange={handlePanelChange}
          handleContinue={handleContinue}
          lat={currentEvent.lat}
          lon={currentEvent.lon}
        />
      }
      {
        shouldDisplayPanel("questions") &&
        <QuestionsPanel
          nextStepLabel={lastPanel === "questions" ? Locale.completeReservation : Locale.continue}
          defaultContact={{
            firstName: currentReservation.firstName,
            lastName: currentReservation.lastName,
            phone: currentReservation.phone,
            email: currentReservation.email,
          }}
          eventQuestions={JSON.parse(
            currentEvent.questionsJson
              ? currentEvent.questionsJson
              : "[]",
          )}
          ticketRequiredFields={currentEvent.ticketRequiredFields}
          rateQuestionGroups={currentEvent.rateQuestionGroups}
          ticketEdits={currentReservation.ticketEdits}
          expanded={expandablePanel("questions") && (panelState["questions"] ?? false)}
          handlePanelChange={handlePanelChange}
          handleContinue={handleContinue}
        />
      }
      {
        shouldDisplayPanel("merchandise") &&
        <>
          <MerchandisePanelComponent
            expanded={expandablePanel("merchandise") && (panelState["merchandise"] ?? false)}
            nextStepLabel={lastPanel == "merchandise" ? Locale.completeReservation : Locale.continue}
            handlePanelChange={handlePanelChange}
            handleContinue={handleContinue}
            addons={catalogItems || []}
            addedAddons={currentReservation.catalogItems || []}
            handleMerchandiseUpdate={handleMerchandiseUpdate}
            giftCard={giftCardInformation}
          />
          {
            (giftCardSelected.catalogId !== 0) ?
              BuildGiftCardComponent({
                name: currentEvent?.addOns?.find(x => x.catalogId === giftCardSelected.catalogId)!.unitPrice!.toString(),
                handleChangeGiftCardInformation: (name: string, value: string, index?: number) => handleChangeGiftCardInformation(name, value, index!, giftCardSelected.catalogId),
                giftCardInformation: giftCardInformation.find(x => x.catalogId === giftCardSelected.catalogId)?.giftCardInformation! ?? [],
                onSuccess: () => SaveGiftCardInformation()
              })
              : null
          }
        </>
      }
      <Snackbar
        open={displayAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseAlert}
        style={{ bottom: "50%" }}
      >
        <Alert severity="error" onClose={handleCloseAlert}>
          {errors[currentError]}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )

}
export const ReservationWizardComponent = React.memo(ReservationWizard)